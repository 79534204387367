import React from "react";
import styled from "styled-components";

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import {
  Years
} from './containers/Years';

import {
  Year2020,
} from './containers/Years/2020'

import {
  Year2021,
} from './containers/Years/2021'

import {
  Year2022,
} from './containers/Years/2022'

import {
  Year2023,
} from './containers/Years/2023'


const FullSizeYears = styled(Years)`
  height: 100%;
  width: 100%;
`

const FullSizeDiv = styled.div`
  height: 100%;
  width: 100%;
`;


export default function App() {
  return (
    <FullSizeDiv>
      <Router>
        <Switch>
          <Route exact path="/">
            <FullSizeYears />
          </Route>
          <Route path="/2020">
            <Year2020 />
          </Route>
          <Route path="/2021">
            <Year2021 />
          </Route>
          <Route path="/2022">
            <Year2022 />
          </Route>
          <Route path="/2023">
            <Year2023 />
          </Route>
        </Switch>
      </Router>
    </FullSizeDiv>
  );
}
