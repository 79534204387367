import React from "react";
import '@google/model-viewer';
import styled, { keyframes } from "styled-components";
import {css} from "styled-components";

const FullScreenDiv = styled.div`
  width: 100%;
  height: 100%;
`;

const TwoDimensionalCanvas = styled.canvas`
  display: none;
`;

const ThreeDimensionalCanvas = styled.canvas`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

const CommandArea = styled.textarea`
  display: none;
`;

const slideIn = keyframes`
    0% {
      -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
              transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0) scaleY(1) scaleX(1);
              transform: translateY(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }

`;

const MessageContainer = styled.div`
  height: 100%;
  z-index: 9999;
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const Message = styled.h1`
  z-index: 9999;
  flex: 0 1 auto;
  align-self: center;

  animation-name: ${slideIn};
  animation-duration: 1s;

  margin: 16px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background: white;
  border-radius: 8px;
  padding: 12px;
`;

const addScript = (url: string) => {
    const script = document.createElement("script")
    script.src = url
    document.body.appendChild(script)

}

export const Year2022 = () => {
  const [scriptLoadingComplete, setScriptLoadingComplete] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState(false);

  React.useEffect(() => {
    addScript("js/origami.js")

    setScriptLoadingComplete(true);
  }, [])

  React.useEffect(() => {
    if (scriptLoadingComplete) {
      setTimeout(() => {
        setShowMessage(true)
      }, 9000);
    }
  }, [scriptLoadingComplete])

  return (
    <FullScreenDiv>
      <TwoDimensionalCanvas id="canvas2d"></TwoDimensionalCanvas>
      <ThreeDimensionalCanvas id="canvas3d"></ThreeDimensionalCanvas>
      <CommandArea id="commandarea"></CommandArea>
      <MessageContainer>
        {showMessage && (
          <Message>I love you</Message>
        )}
      </MessageContainer>
    </FullScreenDiv>
  );
}
