import React from "react";
import styled from 'styled-components';
import emily from '../../../assets/images/emily.png'


const Painting = styled.img`
  max-height: calc(100vh - 80px);
  max-width: calc(100vw - 30px);
`;

const Border = styled.div`
  background: black;
  display: inline-block;
  border: 8px solid #2B432D;
  padding: 6px;
`;

const Row = styled.div`
  height: 100%;
  background: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
`;

const Text = styled.p`
  font-family: 'Kalam', cursive;
  font-size: 22px;
  text-align: right;
  color: #2B432D;
`;

export const Year2023 = () => {

  return (
    <Row>
      <Border>
        <Painting src={emily} alt="" />
        <Text>I love you. And your cold feets.</Text>
      </Border>
    </Row>
  );
}

