import React from 'react';
import styled from 'styled-components';

// @ts-expect-error
import Trianglify from '@planningcenter/react-trianglify';

const BackgroundTrianglify = styled(Trianglify)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Background = (props: {seed: string}) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  React.useEffect(() => {
    const resizeFunc = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    window.addEventListener('resize', resizeFunc);

    return () => {window.removeEventListener('resize', resizeFunc)};
  }, []);

  return (
    <BackgroundTrianglify
      width={width}
      height={height}
      seed={props.seed}
    />
  )
};
