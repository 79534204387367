import styled from 'styled-components';
import { Link } from 'react-router-dom';
import donutImage from '../../assets/images/donut.png'
import letterImage from '../../assets/images/letter.png'
import paperCraneImage from '../../assets/images/envelope.png'
import coldFeets from '../../assets/images/cold-feets.png'


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  width: 100%;
  height: 100%;

  background-image: linear-gradient(to top, #0ba360 0%, #3cba92 100%);
`;

const ImageIcon = styled.img`
  background: white;
  width: 100px;
  height: 100px;
`;

const CardText = styled.span`
  background: white;
  text-align: center;
`;

const CardContainer = styled.div`
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background: white;
  border-radius: 8px;
  padding: 6px;
  display: flex;
  flex-direction: column;
`;

const YearsContainer = styled.div`
  flex: 2 1 auto;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: flex-start
`;

const Year = styled(Link)`
  flex: 0 0 auto;
  padding: 6px;
`;

const Header = styled.h1`
  text-shadow: black 2px 5px;
  flex: 0 1 auto;
  color: white;
  text-align: center;
  padding-top: 12px;
  font-family: 'DK Douceur';
`;

export const Years = () => {
  return (
    <PageContainer>
      <Header>paul loves emily</Header>

      <YearsContainer>
        <Year to="/2020">
          <CardContainer>
            <ImageIcon src={letterImage} alt="" />
            <CardText>2020</CardText>
          </CardContainer>
        </Year>
        <Year to="/2021">
          <CardContainer>
            <ImageIcon src={donutImage} alt="" />
            <CardText>2021</CardText>
          </CardContainer>
        </Year>
        <Year to="/2022">
          <CardContainer>
            <ImageIcon src={paperCraneImage} alt="" />
            <CardText>2022</CardText>
          </CardContainer>
        </Year>
        <Year to="/2023">
          <CardContainer>
            <ImageIcon src={coldFeets} alt="" />
            <CardText>2023</CardText>
          </CardContainer>
        </Year>
      </YearsContainer>

    </PageContainer>
  );
}
