import React from "react";
import '@google/model-viewer';
import styled from "styled-components";

const FullScreenDiv = styled.div`
  width: 100%;
  height: 100%;
`;

const TopOverlayText = styled.h1`
  position: absolute;
  z-index: 9999;
  text-align: center;
  width: 100%;
  padding: 4em 1em 0 1em;
  color: white;
`;

const BottomOverlayText = styled.h1`
  position: absolute;
  z-index: 9999;
  text-align: end;
  width: 100%;
  padding: 4em 1em 1em 1em;
  color: white;
  bottom: 0;
  right: 0;
`;

export const Year2021 = () => {
  return (
    <FullScreenDiv>
      <TopOverlayText>You are the sprinkled donut in a sea of glazed. </TopOverlayText>
      {/* @ts-expect-error */ }
      <model-viewer camera-controls environment-image="neutral" src="./models/Donut_01.gltf"></model-viewer>
      <BottomOverlayText>I love you.</BottomOverlayText>
    </FullScreenDiv>
  );
}
