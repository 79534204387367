import React from "react";
import styled from 'styled-components';
import { Container, Segment } from 'semantic-ui-react'

import { Background } from '../../../components/Background';

const oneMinuteInMilliseconds = 60 * 1000;

const MainHeader = styled(Segment)`
  margin-top: 10%;
`;

const Icon = styled.svg`
  width: 4rem;
  height: 4rem;
`;

export const Year2020 = () => {

  const [date, setDate] = React.useState(new Date().toLocaleDateString());

  React.useEffect(() => {
    const timer = setInterval(() => {
      setDate(
        new Date().toLocaleDateString()
      )
    }, oneMinuteInMilliseconds);

    return () => clearInterval(timer);
  });

  return (
    <Container>
      <Background seed={date} />
      <MainHeader>
        <h2>
          Happy Valentine&apos;s Day Beautiful&nbsp;
          <Icon viewBox="0 0 24 24">
            <path
              fill="rgb(255,0,0)"
              d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4M9.75,7.82C8.21,7.82 7,9.03 7,10.57C7,12.46 8.7,14 11.28,16.34L12,17L12.72,16.34C15.3,14 17,12.46 17,10.57C17,9.03 15.79,7.82 14.25,7.82C13.38,7.82 12.55,8.23 12,8.87C11.45,8.23 10.62,7.82 9.75,7.82Z"
            />
          </Icon>
        </h2>
        <h4>
          This is just something small I wanted to make for you since we
          aren&apos;t supposed to buy each other gifts
        </h4>
        <br />
        <p>
          The background of this website should change everyday at
          midnight but the message will never go away. This is supposed
          to symbolize that even though things around us may change from
          day to day, I won&apos;t ever stop being in love with you (or
          finding cheesy ways to express it).
        </p>
        <p>I love you Emily.</p>
        <p>- Your copilot</p>
      </MainHeader>
    </Container>
  );
}

